/* Overall container to center content and limit width */
.dashboard-container {
  max-width: 900px;
  margin: 0 auto;
  padding: 40px;
  font-family: 'Helvetica Neue', sans-serif;
  background-color: #f9f9f9;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

/* Title styling */
.title {
  text-align: center;
  font-size: 2.5em;
  margin-bottom: 40px;
  color: #2d2d2d;
}

/* Form section for spacing and padding */
.form-section {
  margin-bottom: 40px;
}

/* Flexbox container to align forms vertically */
.form-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

/* Input field styling */
.input-file,
.input-password,
.input-text,
.select-language {
  padding: 15px;
  font-size: 1em;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 100%;
  box-sizing: border-box;
}

/* Submit button styling */
.submit-button {
  padding: 12px 20px;
  font-size: 1.2em;
  color: #fff;
  background-color: #4caf50;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.submit-button:hover {
  background-color: #45a049;
}

/* Center the download section for better user experience */
.download-section {
  text-align: center;
  margin-top: 20px;
}

/* Large circular download button */
.download-button {
  display: inline-block;
  width: 100px;
  height: 100px;
  background-color: #2196f3;
  color: white;
  text-align: center;
  line-height: 100px;
  border-radius: 50%;
  font-size: 1.2em;
  text-decoration: none;
  transition: background-color 0.3s ease;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.download-button:hover {
  background-color: #1e88e5;
}

/* Styling the result container for text and copy button */
.result-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
  margin-top: 20px;
  background-color: #e9f6f9;
  padding: 15px;
  border-radius: 5px;
}

/* Processed text appearance */
.result-text {
  flex: 1;
  font-size: 1.2em;
  color: #333;
  word-wrap: break-word;
}

/* Copy button styling */
.copy-button {
  padding: 8px 16px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.copy-button:hover {
  background-color: #45a049;
}

/* Status message for copy action */
.copy-status {
  font-size: 0.9em;
  color: #4caf50;
}

/* Responsive behavior: Larger screens */
@media (min-width: 768px) {
  .form-container {
    flex-direction: row;
    align-items: center;
    gap: 15px;
  }

  .input-file,
  .input-password,
  .input-text,
  .select-language {
    flex: 1;
    width: auto;
    padding: 12px;
  }

  .submit-button {
    flex: none;
    padding: 10px 20px;
  }
}
